@import '../../../App.scss';

.map-page-wrapper {
  width: 100vw;
  display: flex;
  flex-direction: row;

  .et-apres-container {
    width: 500px;
    @include box-shadow-right;
    background-color: $pink;
    padding: $padding-top-page $big-padding-side;

    height: 100vh;

    p {
      font-size: $paragraph;
      margin-top: $big-padding-side;
    }
  }

  .map-bloc {
    width: calc(100vw - 500px);
    height: 100vh;
    padding-top: 40px;

    .leaflet-container {
      height: calc(100vh - 80px);
    }
  }

  @include media-max('tablet-down') {
    display: block;
    overflow-y: scroll;
    height: calc(100svh - 40px);
    position: relative;
    z-index: -2;

    .et-apres-container,
    .map-bloc {
      width: 100%;
      height: fit-content;
    }

    .et-apres-container {
      padding-top: $big-padding-side;
    }

    .map-bloc {
      height: 50vh;
      position: relative;
      z-index: -1;
      padding-top: 0;

      .leaflet-container {
        height: 50vh;
      }
    }
  }

  @include media-min('tablet-down') {
    .et-apres-container {
      position: absolute;
    }
    .map-bloc {
      overflow: hidden;
      position: absolute;
      right: 0;
      z-index: -1;
    }
  }
}

@import '../../../App.scss';

.about-page-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  z-index: -100;

  div {
    padding: $padding-top-page $big-padding-side;
    height: 100vh;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .registre-container {
    width: 67%;
    @include box-shadow-right;
    background-color: $blue;
    padding-bottom: $padding-bottom-paragraph;

    p {
      font-size: $paragraph;
    }
    a {
      text-decoration: underline;
      font-size: $paragraph;
    }
  }

  .hackaton-container {
    width: 33%;
    padding-bottom: $padding-bottom-paragraph;
    background-color: white;
    z-index: -1;
  }

  @include media-max('tablet-down') {
    display: block;
    height: 100vh;
    overflow-y: scroll;

    .registre-container,
    .hackaton-container {
      width: 100%;
      height: fit-content;
      padding-top: $big-padding-side;
    }

    .registre-container {
      @include box-shadow-down;
    }
  }
}

@import '../../../App.scss';

.residency-detail-wrapper {
  width: calc(100vw - 850px);
  height: 100vh;
  @include box-shadow-right;
  padding-bottom: 0;
  animation: detailOpening ease-in-out 0.3s;
  background-color: #96c1fa;

  .content-wrapper {
    box-sizing: border-box;
    height: calc(100vh - 40px);
    overflow-y: auto;
    scrollbar-width: none;
    background-color: #96c1fa;
    padding: $padding-top-page $big-padding-side;

    &::-webkit-scrollbar {
      display: none;
    }

    .head-residency-detail {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      h1 {
        line-height: 45px;
        width: 80%;
        padding-bottom: $small-padding;
      }

      .cross-plan {
        cursor: pointer;
        z-index: 2000;
        position: absolute;
        top: 50px;
        right: 20px;
        transition: transform 0.3s ease;

        &:hover {
          transform: rotate(90deg);
        }
      }
    }

    .legend-residency {
      font-size: 18px;
      width: 100%;
      padding-bottom: $small-padding;

      li {
        list-style-type: '✧  ';
        list-style-position: inside;
      }
    }

    .tags-wrapper {
      display: flex;
      flex-direction: row;
      padding-top: $small-padding;
      flex-wrap: wrap;

      .tag {
        background-color: white;
        border: solid 1px;
        width: fit-content;
        padding: 0 $small-padding;
        border-radius: 20px;
        font-size: 12px;
        margin-right: $small-padding;
        margin-bottom: $small-padding;
      }
    }

    .events {
      margin-bottom: $small-padding * 3;
      margin-top: $small-padding * 3;

      p {
        padding-bottom: $small-padding;
      }

      .list-item-wrapper {
        border-top: solid 1px black;
      }
    }

    .description-residency {
      margin-top: $small-padding * 2;
    }

    .social-network {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: $small-padding * 2;
      gap: $small-padding * 2;

      .social-network-item {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: $small-padding;
        text-decoration: underline;
      }

      a {
        cursor: pointer;
      }
    }

    img {
      margin: $small-padding * 2 auto;
    }
  }

  @include media-max('tablet-down') {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100% !important;

    @keyframes detailOpening {
      0% {
        translate: 0% 100%;
        z-index: -10;
      }

      1% {
        z-index: 10;
      }

      100% {
        translate: 0% 0%;
        z-index: 10;
      }
    }

    .bloc-scrolling {
      overflow-y: auto;
      height: 100vh;
      padding: 0;
      background-color: #96c1fa;

      .content-wrapper {
        width: 100vw !important;
        overflow-y: hidden;
        height: fit-content;
        @include box-shadow-down;
      }

      .content-map {
        height: calc(100svh - 40px);
        overflow-y: auto;
        padding-top: $big-padding-side;
      }

      .residency-plan-mobile-wrapper {
        width: 100vw;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;

        .residency-plan-wrapper {
          height: fit-content;
          padding: 20px 0 $padding-top-page 0;
          width: 300px;
          display: flex;
          flex-direction: column-reverse;

          .button {
            display: none;
          }

          .floor {
            height: 180px;
            display: flex;
            align-items: center;
            justify-content: center;

            .desktop {
              display: none;
            }
          }
        }
      }
    }
  }

  @include media-max('desktop-down') {
    width: calc(100vw - 680px);

    .content-wrapper {
      width: calc(100vw - 680px);
    }
  }

  @include media-min('tablet-down') {
    z-index: -2;

    @keyframes detailOpening {
      0% {
        translate: -100%;
        z-index: -9;
      }

      99% {
        z-index: -9;
      }

      100% {
        translate: 0%;
        z-index: 20;
      }
    }
  }
}

.residency-detail-wrapper-map {
  @include media-min('tablet-down') {
    width: 500px;
    position: absolute;
    left: 0;
    z-index: 1000;
  }
}

.open {
  background-color: '#96c1fa';
}

.close {
  &:hover {
    background-color: $grey;
  }
}

.closing {
  animation: detailClosing ease-out 0.35s;
  @include media-min('tablet-up') {
    @keyframes detailClosing {
      0% {
        translate: 0%;
        z-index: -10;
      }

      99% {
        z-index: -10;
      }

      100% {
        translate: -100%;
        z-index: -10;
      }
    }
  }

  @include media-max('tablet-down') {
    @keyframes detailClosing {
      0% {
        translate: 0% 0%;
        z-index: 10;
      }

      99% {
        z-index: 10;
      }

      100% {
        translate: 0% 100%;
        z-index: -10;
      }
    }
  }
}

@import '../../../App.scss';

.page-404 {
  width: 100vw;
  height: 100vh;
  background-image: url('../../../assets/image/background-plan.webp');
  background-position: bottom;
  background-size: cover;

  .page-404-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding-top: calc($small-padding * 2);

    div {
      font-size: 45px;
      font-family: Pasteur;
    }

    .error-404 {
      font-size: 350px;
      height: 380px;

      @media (max-width: 750px) {
        font-size: 100px;
        height: auto;
      }
    }
  }
}

//COLORS

$blue: #96c1fa;
$yellow: #fffebe;
$pink: #fae0dd;
$grey: #ececeb;

//PADDING & MARGIN

$padding-top-page: 56px;
$big-padding-side: 24px;
$small-padding: 8px;
$padding-bottom-paragraph: 150px;

//FONT-SIZE

$paragraph: 18px;
$small-paragraph: 16px;
$big-paragraph: 24px;
$menu-height: 40px;

//MIXIN

@mixin box-shadow-right {
  box-shadow: 3px 3px 5px lighten($color: #000000, $amount: 75%);
}

@mixin box-shadow-down {
  box-shadow: 0px 3px 3px lighten($color: #000000, $amount: 75%);
  margin-bottom: 5px;
}

// RESPONSIVE BREAKPOINTS
$breakpoints: (
  'small-screen': 370px,
  'phone-down': 550px,
  'tablet-up': 768px,
  'tablet-down': 950px,
  'desktop-up': 1050px,
  'desktop-down': 1116px,
  'widescreen-up': 1440px
);

// Responsive mixins
/* from... */
@mixin media-min($_key) {
  @media screen and (min-width: map-get($breakpoints, $_key)) {
    & {
      @content;
    }
  }
}

/* to... */
@mixin media-max($_key) {
  @media screen and (max-width: map-get($breakpoints, $_key) - 1) {
    & {
      @content;
    }
  }
}

/* from... to... */
@mixin media-between($_keymin, $_keymax) {
  @media screen and (min-width: map-get($breakpoints, $_keymin)) and (max-width: map-get($breakpoints, $_keymax) - 1) {
    & {
      @content;
    }
  }
}

//GENERAL STYLES

:root {
  --topListHeight: 100px;
  --headerHeight: 100px;
  --overflowYState: 'auto';
  --positionY: 0px;
  --positionX: 0px;
  --roomSize: 27px;
  --roomColor: #ececeb;
  --flexDirection: row;
}

.frontoffice-css-scope {
  font-family: GT-Cinetype !important;
  font-weight: 400;

  h1 {
    font-family: Pasteur;
    font-size: 45px;
    text-transform: lowercase;
  }

  h2 {
    font-family: Pasteur;
    font-size: 35px;
    text-transform: lowercase;
  }

  .intro {
    font-size: $big-paragraph;
  }

  .button {
    border: solid 1px;
    border-radius: 20px;
    background-color: $yellow;
    cursor: pointer;
  }

  .loading-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .p-progress-spinner-circle {
      stroke: #8b8b8b !important;
    }
  }

  .page-wrapper {
    width: 100vw;

    z-index: -1000;
    position: absolute;

    background-image: url('./assets/image/background-plan.webp');
    background-size: cover;

    @include media-min('tablet-down') {
      display: flex;
      flex-direction: row;
    }

    @include media-max('tablet-down') {
      overflow: hidden;
    }
  }

  //Component

  nav {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 40px;
    background-image: url('./assets/image/header.webp');
    background-size: cover;
    background-position: bottom;
    align-items: center;
    position: absolute;
    z-index: 20;
    overflow: hidden;

    @include media-max('tablet-down') {
      bottom: 0px !important;
      background-image: url('./assets/image/bg-404.webp');
    }

    .menu-wrapper-desktop {
      display: flex;
      padding: 0 12px;
      flex-direction: row;
      align-items: center;

      @include media-max('tablet-down') {
        display: none;
      }

      .lien-hp {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;

    .active::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 0;
      background-color: black;
      display: inline-block;
      margin-top: 8px;
      margin-left: -12px;

      position: absolute;

      @include media-max('tablet-down') {
        display: none;
      }
    }

    .menu-title {
      padding-left: 12px;
      padding-right: 12px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .menu-wrapper-mobile {
    display: flex;
    padding: 0 12px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding-top: 8px;
    overflow: hidden;
    padding-bottom: 8px;

    @include media-min('tablet-down') {
      display: none;
    }

    .menu-label {
      font-size: 18px;
      cursor: pointer;

      @media (max-height: 625px) {
        margin-bottom: 10px;
      }
    }
  }

  .menu-mobile {
    height: calc(100svh - 40px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    background-image: url('./assets/image/bg-404.webp');
    background-size: cover;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $big-padding-side;
    padding-bottom: $small-padding;
    animation: menuOpening ease-in-out 0.3s;

    @keyframes menuOpening {
      0% {
        translate: 0% 100%;
        z-index: -10;
      }

      1% {
        z-index: 10;
      }

      100% {
        translate: 0% 0%;
        z-index: 10;
      }
    }

    .menu-items {
      align-self: flex-start;

      li {
        font-family: Pasteur;
        font-size: 64px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        text-transform: lowercase;
        line-height: 60px;
        margin-bottom: 20px;

        @include media-max('small-screen') {
          font-size: 45px;
          line-height: 45px;
        }
      }
    }

    .menu-footer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-transform: uppercase;
      font-size: 18px;

      .lien-hp {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    width: 100vw;
    height: 40px;
    background-image: url('./assets/image/footer.webp');
    background-position: bottom;
    background-size: cover;
    align-items: center;
    position: absolute;
    bottom: 0;

    @include media-max('tablet-down') {
      display: none;
    }
  }

  @include media-max('tablet-down') {
    .desktop {
      display: none !important;
    }

    h1 {
      line-height: 50px;
    }
  }

  @include media-min('tablet-down') {
    .mobile {
      display: none !important;
    }
  }
}

@import '../../../App.scss';

.map-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;

  .left-side {
    width: 35%;
    height: 100vh;

    .intro,
    .residency-detail {
      height: 50%;
      padding: 20px;

      h1 {
        font-size: 25px;
      }
    }

    .intro {
      background-color: #1e293b;
      color: white;
    }

    .residency-detail {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .right-side {
    width: 65%;
    height: 100vh;
    position: relative;
    background-color: green;

    .bloc-autocomplete {
      @apply flex flex-wrap gap-x-3 items-center justify-center w-full top-0 left-0 absolute z-[1000] mt-8 my-8;
    }

    .leaflet-container {
      height: 100vh;
      width: 100%;
    }
  }

  @include media-max('tablet-down') {
    flex-direction: column;
    overflow: scroll;

    .left-side,
    .right-side {
      width: 100%;
      height: fit-content !important;
      overflow: visible;
    }

    .intro,
    .residency-detail {
      width: 100%;
      height: fit-content !important;
      overflow: visible;
    }

    p {
      height: fit-content;
      overflow: visible;
    }

    .leaflet-container {
      height: 100vw !important;
    }

    .desktop {
      display: none !important;
    }

    .bloc-autocomplete {
      display: flex;
      flex-direction: row;
      width: 100vw;
      align-items: flex-end;
      justify-content: space-around;
    }
  }

  @include media-min('tablet-down') {
    .mobile {
      display: none !important;
    }
  }
}

@import '../../../App.scss';

.list-item {
  border-bottom: solid 1px black;
  padding: 16px 8px;
  cursor: pointer;
  background-color: white;
  display: block;

  .list-item-title {
    font-size: 24px;
    line-height: 26px;
  }

  .list-item-tags {
    font-size: 12px;
    padding-top: $small-padding;
  }

  .list-item-rdv {
    padding-top: $small-padding / 2;
    font-size: 12px;
  }

  img {
    position: absolute;
    margin-left: 30px;
    margin-top: -190px;
    width: 250px;
    height: 170px;
    object-fit: contain;
    object-position: left top;
    z-index: 20;

    @include media-max('tablet-down') {
      display: none;
    }
  }
}

.open {
  background-color: $blue;
}

.close {
  &:hover {
    background-color: $grey;
  }
}

.list-residency-detail {
  background-color: $blue;

  @include media-max('tablet-down') {
    background-color: white;
  }
}

.past-residency {
  cursor: auto;
}

@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@font-face {
  font-family: 'Pasteur';
  src:
    local('Pasteur'),
    url('./assets/font/Pasteur.otf') format('OpenType');
}

@font-face {
  font-family: 'GT-Cinetype';
  src: url(' ./assets/font/GT-Cinetype-Bold.otf ') format('OpenType');
  font-weight: 700;
}

@font-face {
  font-family: 'GT-Cinetype';
  src: url('./assets/font/GT-Cinetype-Mono.otf') format('OpenType');
  font-weight: 500;
}

@font-face {
  font-family: 'GT-Cinetype';
  src: url(' ./assets/font/GT-Cinetype-Light.otf ') format('OpenType');
  font-weight: 300;
}

@font-face {
  font-family: 'GT-Cinetype';
  src: url('./assets/font/GT-Cinetype-Regular.otf') format('OpenType');
  font-weight: 400;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.texte1 {
  font-family: Pasteur;
}

@import '../../../App.scss';

.economy-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  z-index: -100;

  .economyyears-wrapper {
    width: 34%;
    height: 100vh;
    background-color: $yellow;
    @include box-shadow-right;
    overflow-y: scroll;
    padding: $padding-top-page $big-padding-side;
    font-size: $paragraph;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .economyyears-content {
      margin-top: 15px;

      .pills-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: $small-padding;

        .year {
          padding: calc($small-padding / 2) $small-padding;
          margin-right: $small-padding;
          &:hover {
            background-color: white;
          }
        }

        .selected {
          background-color: white;
        }
      }
    }
  }

  .economycontent-wrapper {
    width: 66%;
    height: 100vh;
    overflow-y: scroll;
    padding: $padding-top-page $big-padding-side;
    font-size: $paragraph;
    background-color: white;
    z-index: -1;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .economycontent-intro {
      font-size: $big-paragraph;
    }

    .accordion-wrapper {
      margin-top: 40px;
      background-color: white;

      .element-title {
        font-family: Pasteur;
        font-size: 35px;
        padding: 0px $big-padding-side;
        border-radius: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: $small-padding * 2;
        z-index: 10;

        &:hover {
          background-color: $grey;
        }
      }

      .accordion-bottom {
        transition: max-height 0.3s linear;
        overflow: hidden;
        max-height: 0;

        .accordion-legend {
          font-style: italic;
        }

        p {
          font-size: $paragraph;
          margin-top: calc($small-padding * 2);
          margin-bottom: $padding-top-page / 2;
        }

        li {
          margin-left: $small-padding * 2;
        }

        .images-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-top: $padding-top-page / 2;
          flex-wrap: wrap;
          gap: $small-padding;
          height: auto;

          img {
            height: 250px;
          }
        }
      }
    }
  }

  @include media-max('tablet-down') {
    display: block;
    height: calc(100vh - 40px);
    overflow-y: scroll;

    .economyyears-wrapper,
    .economycontent-wrapper {
      width: 100%;
      height: fit-content;
      padding-top: $big-padding-side;

      h1 {
        line-height: 50px;
      }

      .economyyears-content {
        margin-top: $big-padding-side;
      }
    }
    .economyyears-wrapper {
      @include box-shadow-down;
    }

    .accordion-wrapper {
      margin-bottom: $big-padding-side;
    }
  }
}

@import '../../../App.scss';

.archives-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  z-index: 10;

  .left-bloc {
    width: 400px;
    height: 100vh;
    background-color: $yellow;
    @include box-shadow-right;
    overflow-y: scroll;
    padding: $menu-height + $big-padding-side $big-padding-side;
    font-size: $paragraph;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .searchbar {
      border: 1px solid black;
      border-radius: 9px;
      width: 100%;
      height: 38px;
      padding: $small-padding;
    }

    p {
      margin-top: $big-padding-side;
    }

    .pills-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: $big-padding-side 0;
      flex-wrap: wrap;
      gap: $small-padding;

      .year {
        padding: calc($small-padding / 2) $small-padding;
        margin-right: $small-padding;
        border-radius: 20px;
        background-color: $yellow;
        cursor: pointer;
      }
    }

    .abled {
      .selected {
        background-color: white;
      }

      .year {
        border: solid 1px black;

        &:hover {
          background-color: white;
        }
      }
    }

    .disabled {
      .year {
        border: solid 1px black;
        opacity: 0.3;
      }

      .selected {
        background-color: white;
        opacity: 0.5;
      }
    }
  }

  .center-bloc {
    width: 400px;
    height: 100vh;
    background-color: white;
    @include box-shadow-right;
    overflow-y: scroll;
    padding: $menu-height 0;
    font-size: $paragraph;
    z-index: -1;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .type {
      font-family: Pasteur;
      font-size: 45px;
      padding: $menu-height $small-padding 0 $small-padding;
      border-bottom: solid 1px black;
    }
  }

  .right-bloc {
    width: calc(100vw - 800px);
    background-color: $blue;
    z-index: -2;
    padding: $menu-height 0;

    .residency-detail-wrapper {
      width: 100% !important;
    }
  }

  .no-results {
    margin: 50px;
    font-family: Pasteur;
    font-size: 40px;
  }

  @include media-max('tablet-down') {
    overflow-y: scroll;
    height: calc(100dvh - 40px);
    display: block;

    .left-bloc {
      width: 100%;
      height: fit-content;
      padding: $big-padding-side;
    }

    .center-bloc {
      width: 100%;
      display: block;
      overflow: hidden;
      height: fit-content;
      padding: 0;
    }

    .right-bloc {
      width: 100%;
      height: 0 !important;
      padding: 0;
    }
  }
}

@import '../../../App.scss';

.legal-credits-container {
  width: 100vw;
  display: flex;
  flex-direction: row;
  background-color: white;

  div {
    padding: $padding-top-page $big-padding-side;
    height: 100vh;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .legal-container {
    width: 67%;
    @include box-shadow-right;
    padding-bottom: $padding-bottom-paragraph;
  }

  .credits-container {
    width: 33%;
    padding-bottom: $padding-bottom-paragraph;
  }

  p {
    font-size: $big-paragraph;

    a {
      text-decoration: underline;
    }
  }

  @include media-max('tablet-down') {
    display: block;
    height: calc(100vh - 40px);
    overflow-y: scroll;

    .legal-container,
    .credits-container {
      width: 100%;
      padding-top: $big-padding-side;
      height: fit-content;
    }

    .legal-container {
      @include box-shadow-down;
      padding-bottom: $big-padding-side;
    }

    .credits-container {
      padding-bottom: $padding-bottom-paragraph;
    }

    h1 {
      margin-bottom: $small-padding;
    }

    p {
      font-size: $paragraph;
    }
  }
}

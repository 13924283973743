@import '../../../App.scss';

.residency-page {
  width: 100vw;

  .room-circle {
    border-radius: 50%;
    border: 1px solid black;
  }

  .list-wrapper {
    background-color: white;
    width: 350px;
    z-index: 0;
    height: calc(100vh - 40px);
    @include box-shadow-right;

    .yellow-bloc {
      background-color: $yellow;
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: solid 1px black;

      input {
        display: none;
      }
    }

    .top-list {
      height: fit-content;

      .yellow-bloc {
        &:hover {
          background-color: $grey;
        }
      }

      .dropdowns-wrapper {
        width: 100%;
        border-bottom: solid 1px black;
        padding-bottom: 8px;
        padding-right: 8px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .infos {
          display: flex;
          align-items: center;
          justify-content: center;
          border: solid 1px;
          border-radius: 50%;
          z-index: 1;
          cursor: pointer !important;
          background-color: white;
          width: 32px;
          height: 32px;
          margin: 8px 0px 0px 8px;
          position: relative;
        }
      }

      .calendar-pill {
        cursor: pointer;
        height: 43px;
      }

      .calendar-pill-mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border: solid 1px;
        border-radius: 20px;
        padding: 6px 8px;
        z-index: 1;
        cursor: pointer;
        background-color: $yellow;
        width: fit-content;
        flex-wrap: nowrap;
        margin: 8px 0 0 8px;
        height: 32px;
      }

      .calendar {
        position: absolute;
        top: 85px;
        left: 0;
        z-index: 5;
      }
    }

    .list-scroll {
      overflow: auto;
      width: 100%;
      margin-bottom: 40px;
      height: calc(100vh - 80px - var(--topListHeight));
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .floor {
        border-bottom: solid 1px black;
        text-align: center;
        background-color: $grey;
      }

      .aucun-resultat {
        border-bottom: solid 1px black;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
        font-style: italic;
      }

      .bientot {
        height: 50px;
        font-size: 22px;
      }
    }
  }

  .residency-plan-wrapper {
    width: 500px;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
    overflow-y: hidden;
    animation: planOpening ease-in-out 0.35s;

    @include media-min('tablet-down') {
      position: absolute;
      right: 0;
      z-index: -3;
    }

    @include media-max('tablet-down') {
      padding-bottom: 100px !important;
      padding-top: 60px !important;
    }

    @include media-max('desktop-down') {
      width: 400px;
    }

    @include media-min('tablet-down') {
      @keyframes planOpening {
        0% {
          translate: -200%;
          z-index: -10;
        }

        99% {
          z-index: -10;
        }

        100% {
          translate: 0%;
          z-index: 20;
        }
      }
    }

    .cross-plan {
      cursor: pointer;
      z-index: 1000;
      position: absolute;
      top: 50px;
      right: 20px;
      transition: transform 0.3s ease;

      &:hover {
        transform: rotate(90deg);
      }
    }

    .plan-open-wrapper {
      overflow-y: hidden;
      position: absolute;
      height: 100vh;

      .plan-open {
        display: flex;
        flex-direction: column;
        height: fit-content;
        padding: 50vh 0;
        width: 100%;

        .floor {
          height: 50vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
        }

        img {
          width: 400px;
          min-width: 400px;
        }
      }
    }

    .button-up {
      top: 130px;
    }

    .button-down {
      bottom: 130px;
    }
  }

  .plan-wrapper {
    width: calc(100vw - 350px);
    z-index: 0;
    height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    right: 0;
    top: 0;
    background-image: url('../../../assets/image/background-plan.webp');
    background-position: top;
    background-size: cover;
    overflow-y: hidden;

    .legend-container {
      display: flex;
      flex-direction: row;
      position: absolute;
      z-index: 10;
      bottom: 50px;
      left: $small-padding * 2;
      gap: $small-padding / 2;
      width: fit-content;

      div {
        display: flex;
        flex-direction: row;
        gap: $small-padding;
        width: fit-content;
      }

      p {
        font-size: 12px;
        width: 300px;
      }
    }

    .button-up {
      top: 50px;
    }

    .button-down {
      bottom: 50px;
    }

    .button-close {
      right: 30px;
    }

    .plan-open-wrapper {
      position: absolute;
      top: 0;
      overflow: hidden;
      height: 100vh;

      .plan-open {
        display: flex;
        flex-direction: column;
        width: 600px;
        height: fit-content;

        .main-plan {
          cursor:
            url('../../../assets/image/cursor-plan.webp') 55 55,
            auto;
        }

        .open-plan-button {
          position: absolute;
          z-index: 1;
          height: 70px;
          left: 15px;
          top: 25px;
        }

        .floor {
          width: 600px;
          height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .image-wrapper {
            width: 600px;
            height: 400px;
          }
        }
      }
    }
  }

  .image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .room-wrapper {
      position: absolute;
      display: flex;
      align-items: center;

      .room-legend {
        font-size: 10px !important;
        border: 1px solid black;
        height: auto;
        width: auto;
        max-width: 130px;
        text-align: center;
        padding: 0 2px;
      }
    }
  }

  .button-pill {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 20px;
    padding: 3px;
    background-color: white;
    position: absolute;
    z-index: 10;

    &:hover {
      background-color: $grey;
    }
  }

  .residency-closing {
    animation: residencyClosing ease-out 0.4s;
    @include media-min('tablet-up') {
      @keyframes residencyClosing {
        0% {
          translate: 0%;
          z-index: -10;
        }

        99% {
          z-index: -10;
        }

        100% {
          translate: -200%;
          z-index: -10;
        }
      }
    }

    @include media-min('widescreen-up') {
      @keyframes residencyClosing {
        0% {
          translate: 0%;
          z-index: -10;
        }

        99% {
          z-index: -10;
        }

        100% {
          translate: -400%;
          z-index: -10;
        }
      }
    }
  }

  @include media-min('tablet-down') {
    display: flex;
    flex-direction: row;
    position: absolute;
    z-index: -100000;

    .list-wrapper {
      padding-top: 40px;

      .calendar-mobile {
        display: none !important;
      }

      .calendar-pill-mobile {
        display: none !important;
      }

      .infos {
        z-index: 10;
      }

      .glossaire {
        display: none;
      }

      .infos:hover + .glossaire {
        display: block;
        position: absolute;
        z-index: 10000000;
        width: 400px;
        height: fit-content;
        background-color: white;
        top: 110px;
        left: 340px;
        border: black 1px solid;
        border-radius: 10px;
        padding: $small-padding;
        opacity: 0.75;
        font-size: $small-paragraph;

        li {
          list-style-type: circle;
          list-style-position: inside;
        }
      }
    }

    .plan-wrapper {
      position: absolute;
      z-index: -11;
    }
  }

  @include media-max('tablet-down') {
    overflow: scroll;
    height: 100vh;

    .list-wrapper {
      overflow: hidden;
      height: fit-content;
      max-height: 100lvh;
      margin-top: 250px;
      width: 100vw !important;
      position: relative;
      z-index: 6;
      padding-bottom: 200px;

      .calendar-desktop {
        display: none;
      }

      .infos {
        z-index: 0 !important;
      }

      .glossaire {
        position: absolute !important;
        width: 90vw;
        height: auto;
        z-index: 1000000;
        margin: 5vw;
        top: 0px;
        left: 0px;
        background-color: white;
        line-height: 30px;
        padding: $small-padding * 2;
        border: black 1px solid;
        border-radius: 10px;
        font-size: 18px;

        .glossaire-title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: $small-padding * 2;
        }
      }

      .dropdowns-wrapper {
        justify-content: flex-start !important;

        .tag-mobile {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }

        .calendar-pill {
          display: none;
        }

        .calendar {
          top: 50px;
          width: 100vw;
        }
      }

      .list-scroll {
        height: calc(100svh - 40px - var(--topListHeight));
        overflow-y: var(--overflowYState);
      }
    }

    .plan-wrapper {
      height: 0;
      width: 100vw !important;
      left: 0;
      right: 0;

      button {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        z-index: 5;
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .button-hitbox {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: 1px solid;
          border-radius: 20px;
          padding: 3px;
          background-color: white;
          height: 27px;
          width: 27px;
        }
      }

      .button-up {
        top: 10px;
      }

      .button-down {
        top: 200px;
      }

      .button-close {
        right: 10px;
      }

      .legend-container {
        display: none;
      }

      .plan-open-wrapper {
        height: 250px;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;

        .plan-open {
          height: 250px;
          width: 100vw;
          display: flex;
          flex-direction: column;
          align-items: center;

          .floor {
            height: 250px;

            .image-wrapper {
              height: 250px;
              width: 300px;
            }

            .plan-image {
              height: 150px;
              width: 250px;
            }

            .main-plan-image {
              height: 180px;
            }
          }
        }
      }
    }
  }

  @include media-max('desktop-down') {
    .list-wrapper {
      width: 280px;
      min-width: 280px;
    }

    .plan-wrapper {
      width: calc(100vw - 280px);
    }
  }

  @include media-min('tablet-down') {
    .button-close {
      padding: 3px 10px !important;
    }
  }
}

.popup-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgb(255, 255, 255, 0.6);
  z-index: 1000000;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .popup-wrapper {
    width: 392px;
    height: auto;
    background-color: $yellow;
    border: 1px solid black;
    rotate: -3deg;
    padding: 24px;
  }

  .popup-content {
    font-size: $paragraph;
    margin-bottom: $small-padding * 2;
  }

  .residency-pop-wrapper {
    height: fit-content;
    border-top: 1px solid black;
  }

  @include media-max('tablet-down') {
    .popup-wrapper {
      width: 250px;
    }

    .popup-content {
      font-size: $small-paragraph;
    }
  }
}

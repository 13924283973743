@import '../../../App.scss';

.dropdown-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 8px 0px 0px 8px;
  width: fit-content;
  overflow: hidden;

  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: solid 1px;
    border-radius: 20px;
    padding: 6px 8px;
    z-index: 1;
    cursor: pointer;
    background-color: white;
    width: 286px;
    flex-wrap: nowrap;
    height: 32px;

    &:hover {
      background-color: $grey;
    }

    .text-label {
      width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .dropdown-active {
    background-color: $yellow;
  }

  .dropdown-legend-wrapper {
    border: solid 1px;
    position: absolute;
    padding-top: 37px;
    padding-bottom: 5px;
    border-radius: 20px;
    background-color: white;
    overflow-y: auto;
    scrollbar-width: none;

    max-height: 400px !important;

    .dropdown-option {
      margin: 3px 8px;

      cursor: pointer;

      &:hover {
        background-color: $grey;
      }
    }
  }

  @include media-max('tablet-down') {
    label {
      min-width: 70px;
      box-sizing: border-box;
      max-width: 250px;
      width: fit-content !important;
    }

    .dropdown-legend-wrapper {
      box-sizing: border-box;
      min-width: 205px;
      max-width: 250px;
      // overflow: hidden;
    }

    .dropdown-option {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  @include media-min('tablet-down') {
    .dropdown-legend-wrapper {
      min-width: 286px;
      width: fit-content;
    }

    .dropdown-option {
      width: fit-content;
    }
  }

  @include media-max('desktop-down') {
    label {
      width: 210px;
    }
  }
}

.sidebar-content-container {
  @apply flex flex-col justify-between h-full w-full;

  .form-wrapper {
    @apply flex flex-col gap-y-5 pb-20 overflow-y-scroll;
  }
  .manage-footer {
    @apply flex flex-col gap-y-3 pt-3;
  }
}

.backoffice-scope {
  * {
    font-family:
      Montserrat,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
  }

  h2 {
    @apply text-3xl font-semibold;
  }

  h3 {
    @apply text-2xl font-medium;
  }

  .page-wrapper {
    height: calc(100vh - 64px);
    @apply px-5 py-3 w-full overflow-y-auto flex flex-col gap-y-8;
  }

  .data-section {
    @apply flex flex-col gap-y-8;
  }

  .data-section-row {
    @apply flex flex-row gap-5 flex-wrap;
  }

  .empty-section-message {
    @apply italic text-gray-400 py-3 text-sm;
  }

  // DATATABLE
  .p-datatable {
    font-size: 14px !important;
    @apply overflow-hidden text-ellipsis whitespace-nowrap;

    // Header
    .p-datatable-thead > tr > th {
      @apply bg-slate-100;

      .p-inputtext {
        padding: 0.3rem 0.4rem;
        @apply text-sm font-semibold;
      }
    }

    .p-paginator {
      @apply bg-slate-100;
    }

    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
      background-color: transparent;
    }

    .p-button {
      padding: 0.1rem !important;
      height: 30px !important;
      width: 30px !important;
    }

    .p-tag {
      padding: 0.2rem 0.5rem;
    }
  }

  #dashboard-table-with-filters.p-datatable {
    font-size: 14px !important;
    > .p-datatable-wrapper {
      height: calc(100vh - 250px);
    }
  }

  #dashboard-table-without-filters.p-datatable {
    font-size: 14px !important;
    > .p-datatable-wrapper {
      height: calc(100vh - 230px);
    }
  }

  // Dropdown
  .active-custom-dropdown {
    @apply border-slate-800;

    .p-dropdown-trigger {
      @apply text-slate-400 transition-colors;

      &:hover {
        @apply text-slate-800;
      }
    }

    .p-dropdown-clear-icon {
      @apply text-slate-400 transition-colors;

      &:hover {
        @apply text-slate-800;
      }
    }
  }
}

.ql-editor {
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal !important;
  font-size: 17px !important;
  color: #777780;
}
